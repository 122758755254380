import React, { useEffect, useState } from "react";
import SecondLevelCategoryAccordion from "components/category/SecondLevelCategoryAccordion";
import FloatingMenuListButton from "components/FloatingMenuListButton";
import ToggleItemSection from "components/toggle-item-section";
import { useOrder } from "context/order/OrderContext";
import { handleAccordionData } from "pages/menu-listing-page/accordionUtils";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { handleTabTypeInitialAccordionState } from "store/globalSearchAndFilters/globalSearchAndFiltersSlice";
import { IObject } from "types";

interface Props {
  categoryTabData: IObject;
  floatingButtonData: { name: string; helperData?: any }[];
}

const MenuListingTabContent: React.FC<Props> = ({
  categoryTabData,
  floatingButtonData,
}) => {
  const dispatch = useAppDispatch();
  const { orderData, orderIsSuccess } = useOrder();

  const [finalAccordionData, setFinalAccordionData] = useState<any>([]);
  const [addItemQuantity, setAddItemQuantity] = useState<number>(0);
  const tabLabel = useAppSelector(
    (state) => state.tab.selectedTabDetails.homePageHeaderTabs.label
  );

  useEffect(() => {
    if (categoryTabData && Object.keys(categoryTabData).length > 0 && orderIsSuccess) {
      const accordionData = handleAccordionData(
        [categoryTabData],
        orderData,
        setAddItemQuantity
      );
      if (tabLabel.toLowerCase() !== "smoke") {
        dispatch(
          handleTabTypeInitialAccordionState({
            accordionStateArr: accordionData?.map((mainData: any) => mainData?.isMainAccordionOpen),
            tabType: tabLabel.trim().toUpperCase(),
          })
        );
      }
      setFinalAccordionData(accordionData);
    } else {
      setFinalAccordionData([]); // Clear if no categoryTabData
    }
  }, [orderIsSuccess, orderData, categoryTabData, tabLabel, dispatch]);

  return (
    <>
      <div>
        <ToggleItemSection />
        <>
          {finalAccordionData &&
            finalAccordionData.length > 0 &&
            finalAccordionData.map((data: any, index: number) => (
              <div key={index} className="my-[10px]">
                <SecondLevelCategoryAccordion
                  index={index}
                  accordionHeader={data.accordionHeader}
                  accordionHeaderCount={data.accordionHeaderCount}
                  isMainAccordionOpen={data.isMainAccordionOpen}
                  mainAccordionBody={data.mainAccordionBody}
                  isNestedAccordion={data.isNestedAccordion}
                  nestedAccordionBody={data.nestedAccordionBody}
                  helperData={data?.helperData}
                />
              </div>
            ))}
          {floatingButtonData && floatingButtonData.length > 0 && (
            <FloatingMenuListButton menuListData={floatingButtonData} />
          )}
        </>
      </div>
    </>
  );
};

export default MenuListingTabContent;