import menuItemInfo from "assets/images/menu-item-info.svg";
import { rupeeFormatter } from "central-utils/currencyUtils";
import { convertToCapitalizedString } from "central-utils/stringUtils";
import CounterButton from "components/CounterButton";
import CustomToast from "components/CustomToast";
import MenuItemCustomizationModal from "components/menu-item-customization-modal";
import RepeatCustomizationModal from "components/RepeatCustomizationModal";
import { useCartChannel } from "context/pusher/cart";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/customHooks";
import {
  handleMenuItemBannerDisplay,
  handleMenuItemsBannerType,
} from "store/menuCustomization/menuCustomizationSlice";
import {
  useAddMenuItemToOrderByOrderIdMutation,
  useUpdateOrderItemQuantityByProductIdMutation,
} from "store/orders/ordersAPISlice";
import { handleCounterLoader } from "store/tab/tabSlice";

interface Props {
  cardType?: "COMPACT" | "LARGE";
  cardDetails: {
    foodType?: "VEGETARIAN" | "NON_VEGETARIAN" | "EGGETARIAN";
    cardName: string;
    cardImage?: string;
    originalPrice?: number;
    helperData?: any;
    isMenuItemOrdered: boolean;
    initialQuantity: number;
    defaultPrice: number;
  };
  setAddItemQuantity?: (quantity: number) => void;
}

const ThirdLevelCategoryCard: React.FC<Props> = ({
  cardType = "COMPACT",
  cardDetails,
  setAddItemQuantity,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const pusherCartData = useCartChannel();

  //   const foodTypeImage = handleFoodTypeImage(cardDetails?.foodType ?? "");
  const [isCounterVisible, setCounterVisible] = useState(false);
  const [customizationModal, setCustomizationModal] = useState(false);
  const [repeatCustomizationModal, setRepeatCustomizationModal] =
    useState(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [
    repeatCustomizationModalActionType,
    setRepeatCustomizationModalActionType,
  ] = useState<"ADD" | "REMOVE">("ADD");
  const [menuQuantity, setMenuQuantity] = useState(cardDetails.initialQuantity);
  const [orderItemId, setOrderItemId] = useState<string | null>(null);
  const orderId = JSON.parse(sessionStorage.getItem("OrderId") || "{}");

  const [updateQuantity] = useUpdateOrderItemQuantityByProductIdMutation();
  const [addMenuItemToOrder] = useAddMenuItemToOrderByOrderIdMutation();

  const updateCardFromPusher = useCallback(
    (pusherData: any) => {
      if (!pusherData || pusherData.length === 0) {
        setMenuQuantity(0);
        setCounterVisible(false);
        return;
      }
      const productMasterId = cardDetails.helperData.productMaster?.id;
      pusherData.forEach((order: any) => {
        if (order.status === "NEW") {
          let totalQuantity: number = 0;
          order.orderItems.forEach((item: any) => {
            if (item.menuItemMaster?.productMaster?.id === productMasterId) {
              totalQuantity += item.quantity;
              setOrderItemId(item.id);
              item.status === "NEW"
                ? setCounterVisible(true)
                : setCounterVisible(false);
              if (setAddItemQuantity) {
                setAddItemQuantity(item.quantity);
              }
            }
          });
          setMenuQuantity(totalQuantity);
        } else {
          setMenuQuantity(0);
          setCounterVisible(false);
        }
      });
    },
    [cardDetails, setAddItemQuantity]
  );

  useEffect(() => {
    if (cardDetails.isMenuItemOrdered && cardDetails.initialQuantity > 0) {
      // setCounterVisible(true);
      setOrderItemId(cardDetails?.helperData?.orderItemHelperData?.id || null);
    }
  }, [cardDetails]);

  useEffect(() => {
    updateCardFromPusher(pusherCartData);
  }, [pusherCartData, updateCardFromPusher]);
  useEffect(() => {
    if (menuQuantity === 0) setCounterVisible(false);
  }, [menuQuantity]);

  const handleAddButtonClick = async () => {
    const servingSizeId =
      cardDetails?.helperData?.menuItems[0]?.menuItemMasterId;
    dispatch(handleCounterLoader({ itemId: servingSizeId, isLoading: true }));

    const customerId = JSON.parse(sessionStorage.getItem("CustomerId") || "{}");

    let payload: any = {
      quantity: 1,
      menuItemMaster: { id: servingSizeId },
      price: cardDetails?.originalPrice,
    };

    if (typeof customerId !== "object") {
      payload.customerId = customerId;
    }

    try {
      const res = await addMenuItemToOrder({ orderId, menuItemsBody: payload });
      if (res?.data) {
        if (res.data.orderItemId) {
          setOrderItemId(res.data.orderItemId);
          setMenuQuantity(1);
          setCounterVisible(true);
          if (setAddItemQuantity) {
            setAddItemQuantity(1);
            dispatch(handleMenuItemsBannerType("ADD"));
            dispatch(handleMenuItemBannerDisplay(true));
          }
        }
      } else {
        throw new Error(res?.error?.data?.message || "Failed to add item");
      }
    } catch (error: any) {
      console.error("Error during ADD operation: ", error);
      setToastMessage(error.message);
      if (
        error.message ===
        "Order is not in NEW status. Cannot add or update items."
      ) {
        navigate("/payment-in-progress-block");
      }
    } finally {
      dispatch(
        handleCounterLoader({ itemId: servingSizeId, isLoading: false })
      );
    }
  };

  const handleQuantityChange = async (updatedQuantity: number) => {
    if (!orderItemId) {
      console.error("OrderItemId is not available");
      return;
    }

    dispatch(
      handleCounterLoader({
        itemId: cardDetails?.helperData?.menuItems[0]?.menuItemMasterId,
        isLoading: true,
      })
    );

    if (
      (cardDetails?.helperData?.modifier ||
        cardDetails?.helperData?.instructions?.length > 0 ||
        cardDetails?.helperData?.menuItems?.length > 1) &&
      updatedQuantity >= 1
    ) {
      setRepeatCustomizationModalActionType(
        updatedQuantity > menuQuantity ? "ADD" : "REMOVE"
      );
      setRepeatCustomizationModal(true);
      dispatch(
        handleCounterLoader({
          itemId: cardDetails?.helperData?.menuItems[0]?.menuItemMasterId,
          isLoading: false,
        })
      );
    } else {
      try {
        const res = await updateQuantity({
          orderItemId: orderItemId,
          price: cardDetails?.originalPrice,
          quantity: updatedQuantity,
        });

        if (res?.data || res?.data === null) {
          setMenuQuantity(updatedQuantity);
          if (updatedQuantity === 0) {
            setCounterVisible(false);
            setOrderItemId(null);
          }
          if (setAddItemQuantity) {
            setAddItemQuantity(updatedQuantity);
          }
        } else {
          throw new Error(
            res?.error?.data?.message || "Failed to update quantity"
          );
        }
      } catch (error: any) {
        console.error("Error updating quantity:", error);
        setToastMessage(error.message);
        if (
          error.message ===
          "Order is not in NEW status. Cannot add or update items."
        ) {
          navigate("/payment-in-progress-block");
        }
      } finally {
        dispatch(
          handleCounterLoader({
            itemId: cardDetails?.helperData?.menuItems[0]?.menuItemMasterId,
            isLoading: false,
          })
        );
      }
    }
  };

  // Clear The Toast Message when the toast is removed
  const handleOnToastDismiss = () => {
    setToastMessage(null);
  };

  // Render the toast message if available
  const renderToast = () => {
    if (toastMessage) {
      return (
        <CustomToast
          message={toastMessage}
          handleOnToastDismiss={handleOnToastDismiss}
        />
      );
    }
    return null;
  };

  const handleCardWithoutImage = () => {
    return (
      <>
        <div className="flex flex-col gap-2">
          <div className="flex justify-between thirdLevelCategoryCard withoutImageCard w-full rounded-xl p-2 pr-0 ">
            <div className=" h-full flex flex-col pl-2 gap-4  justify-between">
              <div className="flex flex-col gap-1">
                <div className="cardText">
                  <span className="inline menuItemName">
                    {convertToCapitalizedString(cardDetails?.cardName)}
                  </span>
                </div>
                <div className="cardDescription line-clamp-2">
                  {cardDetails?.helperData?.description}
                </div>
              </div>
              <div className="flex flex-row items-center gap-6">
                {isCounterVisible ? (
                  <CounterButton
                    buttonType="SMALL"
                    disableQuantityUpdate
                    initialQuantity={menuQuantity}
                    onQuantityChange={handleQuantityChange}
                    itemId={
                      cardDetails?.helperData?.menuItems[0]?.menuItemMasterId
                    }
                  />
                ) : (
                  <div className="flex flex-col items-center gap-0.85">
                    <button
                      onClick={() => {
                        if (
                          cardDetails?.helperData?.modifier ||
                          cardDetails?.helperData?.instructions?.length > 0 ||
                          cardDetails?.helperData?.menuItems?.length > 1
                        ) {
                          setCustomizationModal(true);
                        } else {
                          handleAddButtonClick();
                        }
                      }}
                      className="addButton"
                    >
                      ADD
                    </button>
                    {(cardDetails?.helperData?.modifier ||
                      cardDetails?.helperData?.instructions?.length > 0 ||
                      cardDetails?.helperData?.menuItems?.length > 1) && (
                        <span className="text-[10px] font-normal cardDescription mt-0.5">
                          Customisable
                        </span>
                      )}
                  </div>
                )}
                <div className="flex flex-col items-start gap-0.5">
                  {(cardDetails?.helperData?.modifier ||
                    cardDetails?.helperData?.instructions?.length > 0 ||
                    cardDetails?.helperData?.menuItems?.length > 1) && (
                      <div className="customizablePriceText">STARTS AT</div>
                    )}
                  <div className="cardText">
                    {cardDetails?.defaultPrice > 0 &&
                      cardDetails?.defaultPrice > (cardDetails?.originalPrice || 0) &&
                      cardDetails?.defaultPrice !== null ? (
                      <span className="line-through text-[#D20000] mr-[5px]">
                        {rupeeFormatter(cardDetails?.defaultPrice)}/-
                      </span>
                    ) : null}
                    <span>{rupeeFormatter(cardDetails?.originalPrice)}/-</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-end">
              <div
                className=" relative right-0 bottom-0"
                onClick={() => {
                  setCustomizationModal(true);
                }}
              >
                <img src={menuItemInfo} alt="Info" />
              </div>
            </div>
          </div>
          <div className="border-t border-[#F5F2F2] mx-1"></div>
        </div>
        {customizationModal && (
          <MenuItemCustomizationModal
            productMasterId={cardDetails?.helperData?.productMaster?.id}
            onClose={() => {
              setCustomizationModal(false);
            }}
            setAddItemQuantity={setAddItemQuantity}
          />
        )}
        {repeatCustomizationModal && (
          <RepeatCustomizationModal
            actionType={repeatCustomizationModalActionType}
            productMasterId={cardDetails?.helperData?.productMaster?.id}
            isOpen={repeatCustomizationModal}
            onClose={() => setRepeatCustomizationModal(false)}
          />
        )}
        {renderToast()}
      </>
    );
  };

  const handleCompactCard = () => {
    return (
      <div>
        <div className="flex flex-col gap-2">
          <div className="flex justify-between thirdLevelCategoryCard compactCard w-full p-1 gap-2">
            <div className="h-full flex flex-col pl-2 justify-between">
              <div className="flex flex-col gap-1">
                <div className="cardText">
                  <span className="inline menuItemName">
                    {convertToCapitalizedString(cardDetails?.cardName)}
                  </span>
                </div>
                <div className="cardDescription line-clamp-2">
                  {cardDetails?.helperData?.description}
                </div>
              </div>
              <div className="flex flex-row items-center gap-6">
                {isCounterVisible ? (
                  <CounterButton
                    buttonType="SMALL"
                    disableQuantityUpdate
                    initialQuantity={menuQuantity}
                    onQuantityChange={handleQuantityChange}
                    itemId={
                      cardDetails?.helperData?.menuItems[0]?.menuItemMasterId
                    }
                  />
                ) : (
                  <div className="flex flex-col items-center gap-0.85">
                    <button
                      onClick={() => {
                        if (
                          cardDetails?.helperData?.modifier ||
                          cardDetails?.helperData?.instructions?.length > 0 ||
                          cardDetails?.helperData?.menuItems?.length > 1
                        ) {
                          setCustomizationModal(true);
                        } else {
                          handleAddButtonClick();
                        }
                      }}
                      className="addButton"
                    >
                      ADD
                    </button>
                    {(cardDetails?.helperData?.modifier ||
                      cardDetails?.helperData?.instructions?.length > 0 ||
                      cardDetails?.helperData?.menuItems?.length > 1) && (
                        <span className="text-[10px] font-normal cardDescription mt-0.5">
                          Customisable
                        </span>
                      )}
                  </div>
                )}
                <div className="flex flex-col items-start gap-0.5">
                  {(cardDetails?.helperData?.modifier ||
                    cardDetails?.helperData?.instructions?.length > 0 ||
                    cardDetails?.helperData?.menuItems?.length > 1) && (
                      <div className="customizablePriceText">STARTS AT</div>
                    )}
                  <div className="cardText">
                    {cardDetails?.defaultPrice > 0 &&
                      cardDetails?.defaultPrice > (cardDetails?.originalPrice || 0) &&
                      cardDetails?.defaultPrice !== null ? (
                      <span className="line-through text-[#D20000] mr-[5px]">
                        {rupeeFormatter(cardDetails?.defaultPrice)}/-
                      </span>
                    ) : null}
                    {rupeeFormatter(cardDetails?.originalPrice)}/-
                  </div>
                </div>
              </div>
            </div>
            <div className="h-full flex flex-col justify-start items-center relative">
              <img
                src={cardDetails.cardImage}
                alt={cardDetails.cardName}
                className="object-cover compactCardImage overflow-hidden"
              />
              <div
                className="absolute bottom-1 right-0"
                onClick={() => {
                  setCustomizationModal(true);
                }}
              >
                <img src={menuItemInfo} alt="Info" />
              </div>
            </div>
          </div>
          <div className="border-t border-[#F5F2F2] mx-1"></div>
        </div>
        {customizationModal && (
          <MenuItemCustomizationModal
            productMasterId={cardDetails?.helperData?.productMaster?.id}
            onClose={() => {
              setCustomizationModal(false);
            }}
            setAddItemQuantity={setAddItemQuantity}
          />
        )}
        {repeatCustomizationModal && (
          <RepeatCustomizationModal
            actionType={repeatCustomizationModalActionType}
            productMasterId={cardDetails?.helperData?.productMaster?.id}
            isOpen={repeatCustomizationModal}
            onClose={() => setRepeatCustomizationModal(false)}
          />
        )}
        {renderToast()}
      </div>
    );
  };

  const handleLargeCard = () => {
    return (
      <div className="thirdLevelCategoryCard largeCard w-full p-1">
        <img
          src={cardDetails.cardImage}
          alt={cardDetails.cardName}
          className="w-full largeCardImage overflow-hidden"
        />
        <div className="w-full p-1 flex">
          <div className="w-3/4 h-full flex flex-col justify-center pt-1">
            <div className="flex items-center">
              <div></div>
              <div className="cardText">
                <span className="inline">
                  {convertToCapitalizedString(cardDetails?.cardName)}
                </span>
              </div>
            </div>
            <div className="cardText pt-[5px]">
              {rupeeFormatter(cardDetails?.originalPrice)}/-
            </div>
          </div>
          <div className="w-[95px] flex justify-center items-center">
            {cardDetails?.isMenuItemOrdered &&
              cardDetails?.initialQuantity !== 0 ? (
              <div>
                <CounterButton
                  buttonType="SMALL"
                  disableQuantityUpdate
                  initialQuantity={menuQuantity}
                  onQuantityChange={handleQuantityChange}
                  itemId={
                    cardDetails?.helperData?.menuItems[0]?.menuItemMasterId
                  }
                />
              </div>
            ) : (
              <button
                onClick={() => {
                  setCustomizationModal(true);
                }}
                className="addButton"
              >
                ADD
              </button>
            )}
          </div>
        </div>
        {customizationModal && (
          <MenuItemCustomizationModal
            productMasterId={cardDetails?.helperData?.productMaster?.id}
            onClose={() => {
              setCustomizationModal(false);
            }}
            setAddItemQuantity={setAddItemQuantity}
          />
        )}
        {repeatCustomizationModal && (
          <RepeatCustomizationModal
            actionType={repeatCustomizationModalActionType}
            productMasterId={cardDetails?.helperData?.productMaster?.id}
            isOpen={repeatCustomizationModal}
            onClose={() => setRepeatCustomizationModal(false)}
          />
        )}
        {renderToast()}
      </div>
    );
  };

  switch (cardType) {
    case "COMPACT":
    case "LARGE":
      if (
        cardDetails &&
        Object.keys(cardDetails).includes("cardImage") &&
        cardDetails?.cardImage !== undefined &&
        cardDetails?.cardImage !== null
      ) {
        return cardType === "COMPACT" ? handleCompactCard() : handleLargeCard();
      } else {
        return handleCardWithoutImage();
      }
    default:
      return null;
  }
};

export default ThirdLevelCategoryCard;
